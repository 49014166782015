import React from "react";
import './Preloader.css';

function Preloader(props) {
  return (
    <div className={`preloader preloader--${props.position}`}>

      <svg
        className={`preloader__image preloader__image--${props.position}`}
        version="1.0"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="800px"
        height="800px"
        viewBox="-6.4 -6.4 76.80 76.80"
        xmlSpace="preserve"
        fill="#000000"
        transform="rotate(0)"
        stroke="#000000"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.384"></g>
        <g id="SVGRepo_iconCarrier">
          <g>
            <path
              fill="#000000"
              d="M32,50c7.732,0,14-6.268,14-14s-6.268-14-14-14s-14,6.268-14,14S24.268,50,32,50z M32,24 c6.627,0,12,5.373,12,12s-5.373,12-12,12s-12-5.373-12-12S25.373,24,32,24z"
            />
            <path
              fill="#000000"
              d="M60,12c0,0-7,0-8,0s-1.582,0.004-2.793-1.207s-5.538-5.538-5.538-5.538C43.481,5.067,42.33,4,41,4 S24.453,4,23,4s-2.498,1.084-2.686,1.271c0,0-4.326,4.326-5.521,5.521S13.018,12,12,12V9c0-0.553-0.447-1-1-1H5 C4.447,8,4,8.447,4,9v3c-2.211,0-4,1.789-4,4v40c0,2.211,1.789,4,4,4h56c2.211,0,4-1.789,4-4V16C64,13.789,62.211,12,60,12z M6,10 h4v2c-1.24,0-2.782,0-4,0V10z M4,14c0,0,8,0,9,0s2.027-0.613,2.707-1.293s5.281-5.281,5.984-5.984S23.5,6,23.5,6h17 c0,0,1.074-0.012,1.832,0.746s5.215,5.215,5.961,5.961S50,14,51,14s9,0,9,0c1.104,0,2,0.896,2,2v12H48.107 C45.16,22.078,39.063,18,32,18s-13.16,4.078-16.107,10H2V16C2,14.896,2.896,14,4,14z M32,20c8.837,0,16,7.163,16,16 c0,8.838-7.163,16-16,16s-16-7.162-16-16C16,27.163,23.163,20,32,20z M60,58H4c-1.104,0-2-0.896-2-2V30h13.046 C14.381,31.879,14,33.893,14,36c0,9.941,8.059,18,18,18s18-8.059,18-18c0-2.107-0.381-4.121-1.046-6H62v26 C62,57.104,61.104,58,60,58z"
            />
            <path
              id="rotating-ring"
              fill="#000000"
              d="M32,30c3.313,0,6,2.687,6,6c0,0.553,0.447,1,1,1s1-0.447,1-1c0-4.418-3.582-8-8-8c-0.553,0-1,0.447-1,1 S31.447,30,32,30z"
            />
            <path
              fill="#000000"
              d="M55,24c1.657,0,3-1.344,3-3s-1.343-3-3-3s-3,1.344-3,3S53.343,24,55,24z M55,20c0.553,0,1,0.447,1,1 s-0.447,1-1,1s-1-0.447-1-1S54.447,20,55,20z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Preloader;