export const devCommercialList = [
  {
    name: "alinecollection_red",
    title: "Alinecollection",
    subtitle: "",
    previews: [
      {
        name: "commercial-alinecollection_red-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-alinecollection_red-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-alinecollection_red-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-alinecollection_red-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection_red-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection_red-003.jpg",
        position: "horizontal",
      },
      {
        name: "commercial-alinecollection_red-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection_red-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection_red-006.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection_red-007.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection_red-008.jpg",
        position: "horizontal",
      },
      {
        name: "commercial-alinecollection_red-009.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection_red-010.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection_red-011.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection_red-012.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection_red-013.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection_red-014.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection_red-015.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection_red-016.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection_red-017.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection_red-018.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection_red-019.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection_red-020.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection_red-021.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection_red-022.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "viva_la_vika",
    title: "Viva La Vika",
    subtitle: "",
    previews: [
      {
        name: "commercial-viva_la_vika-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-viva_la_vika-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-viva_la_vika-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-viva_la_vika-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-viva_la_vika-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-viva_la_vika-003.jpg",
        position: "horizontal",
      },
      {
        name: "commercial-viva_la_vika-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-viva_la_vika-005.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "petelka",
    title: "Petelka",
    subtitle: "",
    previews: [
      {
        name: "commercial-petelka-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-petelka-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-petelka-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-petelka-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-003.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-006.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-007.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-008.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-009.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-010.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-011.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-012.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-013.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-014.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-015.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-016.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-017.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-018.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-019.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-020.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-021.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-022.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-023.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-024.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-025.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-026.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-027.jpg",
        position: "vertical",
      },
      {
        name: "commercial-petelka-028.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "bodymovement_room",
    title: "Bodymovement",
    subtitle: "",
    previews: [
      {
        name: "commercial-bodymovement_room-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-bodymovement_room-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-bodymovement_room-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-bodymovement_room-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-bodymovement_room-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-bodymovement_room-003.jpg",
        position: "horizontal",
      },
      {
        name: "commercial-bodymovement_room-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-bodymovement_room-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-bodymovement_room-006.jpg",
        position: "vertical",
      },
      {
        name: "commercial-bodymovement_room-007.jpg",
        position: "vertical",
      },
      {
        name: "commercial-bodymovement_room-008.jpg",
        position: "vertical",
      },
      {
        name: "commercial-bodymovement_room-009.jpg",
        position: "vertical",
      },
      {
        name: "commercial-bodymovement_room-010.jpg",
        position: "vertical",
      },
      {
        name: "commercial-bodymovement_room-011.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "the_guno",
    title: "The Guno",
    subtitle: "",
    previews: [
      {
        name: "commercial-the_guno-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-the_guno-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-the_guno-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-the_guno-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_guno-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_guno-003.jpg",
        position: "horizontal",
      },
      {
        name: "commercial-the_guno-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_guno-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_guno-006.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_guno-007.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_guno-008.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_guno-009.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_guno-010.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_guno-011.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_guno-012.JPG",
        position: "vertical",
      },
      {
        name: "commercial-the_guno-013.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_guno-014.jpg",
        position: "horizontal",
      },
      {
        name: "commercial-the_guno-015.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_guno-016.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_guno-017.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_guno-018.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_guno-019.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_guno-020.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "delarise",
    title: "Delarise",
    subtitle: "",
    previews: [
      {
        name: "commercial-delarise-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-delarise-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-delarise-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-delarise-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-delarise-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-delarise-003.jpg",
        position: "vertical",
      },
      {
        name: "commercial-delarise-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-delarise-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-delarise-006.jpg",
        position: "vertical",
      },
      {
        name: "commercial-delarise-007.jpg",
        position: "vertical",
      },
      {
        name: "commercial-delarise-008.jpg",
        position: "vertical",
      },
      {
        name: "commercial-delarise-009.jpg",
        position: "vertical",
      },
      {
        name: "commercial-delarise-010.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "alinecollection",
    title: "Alinecollection",
    subtitle: "",
    previews: [
      {
        name: "commercial-alinecollection-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-alinecollection-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-alinecollection-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-alinecollection-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection-003.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection-006.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection-007.jpg",
        position: "vertical",
      },
      {
        name: "commercial-alinecollection-008.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "selfmade",
    title: "Selfmade",
    subtitle: "",
    previews: [
      {
        name: "commercial-selfmade-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-selfmade-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-selfmade-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-selfmade-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-selfmade-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-selfmade-003.jpg",
        position: "vertical",
      },
      {
        name: "commercial-selfmade-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-selfmade-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-selfmade-006.jpg",
        position: "vertical",
      },
      {
        name: "commercial-selfmade-007.jpg",
        position: "vertical",
      },
      {
        name: "commercial-selfmade-008.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "charmstore",
    title: "Charmstore",
    subtitle: "",
    previews: [
      {
        name: "commercial-charmstore-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-charmstore-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-charmstore-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-charmstore-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-charmstore-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-charmstore-003.jpg",
        position: "horizontal",
      },
      {
        name: "commercial-charmstore-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-charmstore-005.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "emka",
    title: "Emka",
    subtitle: "",
    previews: [
      {
        name: "commercial-emka-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-emka-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-emka-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-emka-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-emka-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-emka-003.jpg",
        position: "vertical",
      },
      {
        name: "commercial-emka-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-emka-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-emka-006.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "finn_flare_premium",
    title: "Finn Flare",
    subtitle: "Premium",
    previews: [
      {
        name: "commercial-finn_flare_premium-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-finn_flare_premium-preview-002.JPG",
        type: "preview_first",
      },
      {
        name: "commercial-finn_flare_premium-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-finn_flare_premium-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-finn_flare_premium-002.JPG",
        position: "vertical",
      },
      {
        name: "commercial-finn_flare_premium-003.jpg",
        position: "horizontal",
      },
      {
        name: "commercial-finn_flare_premium-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-finn_flare_premium-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-finn_flare_premium-006.JPG",
        position: "vertical",
      },
      {
        name: "commercial-finn_flare_premium-007.jpg",
        position: "vertical",
      },
      {
        name: "commercial-finn_flare_premium-008.jpg",
        position: "vertical",
      },
      {
        name: "commercial-finn_flare_premium-009.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "giotto",
    title: "Giotto",
    subtitle: "",
    previews: [
      {
        name: "commercial-giotto-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-giotto-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-giotto-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-giotto-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-giotto-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-giotto-003.jpg",
        position: "vertical",
      },
      {
        name: "commercial-giotto-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-giotto-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-giotto-006.jpg",
        position: "vertical",
      },
      {
        name: "commercial-giotto-007.jpg",
        position: "vertical",
      },
      {
        name: "commercial-giotto-008.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "shelk",
    title: "Shelk",
    subtitle: "",
    previews: [
      {
        name: "commercial-shelk-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-shelk-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-shelk-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-shelk-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-shelk-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-shelk-003.jpg",
        position: "horizontal",
      },
      {
        name: "commercial-shelk-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-shelk-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-shelk-006.jpg",
        position: "vertical",
      },
      {
        name: "commercial-shelk-007.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "nude_story",
    title: "Nude Story",
    subtitle: "",
    previews: [
      {
        name: "commercial-nude_story-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-nude_story-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-nude_story-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-nude_story-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-nude_story-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-nude_story-003.jpg",
        position: "vertical",
      },
      {
        name: "commercial-nude_story-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-nude_story-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-nude_story-006.jpg",
        position: "vertical",
      },
      {
        name: "commercial-nude_story-007.jpg",
        position: "horizontal",
      },
      {
        name: "commercial-nude_story-008.jpg",
        position: "vertical",
      },
      {
        name: "commercial-nude_story-009.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "leraw",
    title: "Le'raw",
    subtitle: "",
    previews: [
      {
        name: "commercial-leraw-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-leraw-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-leraw-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-leraw-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-leraw-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-leraw-003.jpg",
        position: "vertical",
      },
      {
        name: "commercial-leraw-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-leraw-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-leraw-006.jpg",
        position: "vertical",
      },
      {
        name: "commercial-leraw-007.jpg",
        position: "vertical",
      },
      {
        name: "commercial-leraw-008.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "level44",
    title: "Level44",
    subtitle: "",
    previews: [
      {
        name: "commercial-level44-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-level44-preview-002.JPG",
        type: "preview_first",
      },
      {
        name: "commercial-level44-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-level44-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-level44-002.JPG",
        position: "vertical",
      },
      {
        name: "commercial-level44-003.jpg",
        position: "vertical",
      },
      {
        name: "commercial-level44-004.JPG",
        position: "vertical",
      },
      {
        name: "commercial-level44-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-level44-006.jpg",
        position: "vertical",
      },
      {
        name: "commercial-level44-007.jpg",
        position: "vertical",
      },
      {
        name: "commercial-level44-008.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "tatmu",
    title: "Tatmu",
    subtitle: "",
    previews: [
      {
        name: "commercial-tatmu-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-tatmu-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-tatmu-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-tatmu-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-tatmu-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-tatmu-003.jpg",
        position: "horizontal",
      },
      {
        name: "commercial-tatmu-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-tatmu-005.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "select_studio",
    title: "Select Studio",
    subtitle: "",
    previews: [
      {
        name: "commercial-select_studio-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-select_studio-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-select_studio-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-select_studio-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-select_studio-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-select_studio-003.jpg",
        position: "vertical",
      },
      {
        name: "commercial-select_studio-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-select_studio-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-select_studio-006.jpg",
        position: "vertical",
      },
      {
        name: "commercial-select_studio-007.jpg",
        position: "vertical",
      },
      {
        name: "commercial-select_studio-008.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "joserjewellery",
    title: "Joserjewellery",
    subtitle: "",
    previews: [
      {
        name: "commercial-joserjewellery-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-joserjewellery-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-joserjewellery-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-joserjewellery-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-joserjewellery-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-joserjewellery-003.jpg",
        position: "vertical",
      },
      {
        name: "commercial-joserjewellery-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-joserjewellery-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-joserjewellery-006.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "aurorajoaillerie",
    title: "Aurora.joaillerie",
    subtitle: "",
    previews: [
      {
        name: "commercial-aurorajoaillerie-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-aurorajoaillerie-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-aurorajoaillerie-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-aurorajoaillerie-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-aurorajoaillerie-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-aurorajoaillerie-003.jpg",
        position: "horizontal",
      },
      {
        name: "commercial-aurorajoaillerie-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-aurorajoaillerie-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-aurorajoaillerie-006.jpg",
        position: "vertical",
      },
      {
        name: "commercial-aurorajoaillerie-007.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "kapsula",
    title: "Kapsula",
    subtitle: "",
    previews: [
      {
        name: "commercial-kapsula-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-kapsula-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-kapsula-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-kapsula-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-kapsula-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-kapsula-003.jpg",
        position: "vertical",
      },
      {
        name: "commercial-kapsula-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-kapsula-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-kapsula-006.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "the_ninki",
    title: "The Ninki",
    subtitle: "",
    previews: [
      {
        name: "commercial-the_ninki-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-the_ninki-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-the_ninki-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-the_ninki-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_ninki-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_ninki-003.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_ninki-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_ninki-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_ninki-006.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "2mood",
    title: "2MOOD",
    subtitle: "",
    previews: [
      {
        name: "commercial-2mood-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-2mood-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-2mood-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-2mood-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-2mood-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-2mood-003.jpg",
        position: "vertical",
      },
      {
        name: "commercial-2mood-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-2mood-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-2mood-006.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "measure",
    title: "Measure",
    subtitle: "",
    previews: [
      {
        name: "commercial-measure-preview-001.JPG",
        type: "preview_magnum",
      },
      {
        name: "commercial-measure-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-measure-preview-003.JPG",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-measure-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-measure-002.JPG",
        position: "vertical",
      },
      {
        name: "commercial-measure-003.jpg",
        position: "horizontal",
      },
      {
        name: "commercial-measure-004.JPG",
        position: "vertical",
      },
      {
        name: "commercial-measure-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-measure-006.JPG",
        position: "vertical",
      },
      {
        name: "commercial-measure-007.jpg",
        position: "vertical",
      },
      {
        name: "commercial-measure-008.jpg",
        position: "vertical",
      },
      {
        name: "commercial-measure-009.JPG",
        position: "vertical",
      },
    ],
  },
  {
    name: "choux",
    title: "Choux",
    subtitle: "",
    previews: [
      {
        name: "commercial-choux-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-choux-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-choux-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-choux-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-choux-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-choux-003.jpg",
        position: "vertical",
      },
      {
        name: "commercial-choux-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-choux-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-choux-006.jpg",
        position: "vertical",
      },
      {
        name: "commercial-choux-007.jpg",
        position: "vertical",
      },
      {
        name: "commercial-choux-008.jpg",
        position: "vertical",
      },
    ],
  },
  {
    name: "the_ninki_new_year_collection",
    title: "The Ninki",
    subtitle: "New Year Collection",
    previews: [
      {
        name: "commercial-the_ninki_new_year_collection-preview-001.jpg",
        type: "preview_magnum",
      },
      {
        name: "commercial-the_ninki_new_year_collection-preview-002.jpg",
        type: "preview_first",
      },
      {
        name: "commercial-the_ninki_new_year_collection-preview-003.jpg",
        type: "preview_second",
      },
    ],
    photos: [
      {
        name: "commercial-the_ninki_new_year_collection-001.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_ninki_new_year_collection-002.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_ninki_new_year_collection-003.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_ninki_new_year_collection-004.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_ninki_new_year_collection-005.jpg",
        position: "vertical",
      },
      {
        name: "commercial-the_ninki_new_year_collection-006.jpg",
        position: "vertical",
      },
    ],
  },
]